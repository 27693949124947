import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StockCountAdjustmentProvider extends HttpRequest {
  getByStockCountId (stockCountId) {
    this.setHeader(getAuthToken())
    return this.get(`stock-count-adjustment/stock-count-id/${stockCountId}`)
  }

  getById (stockAdjId) {
    this.setHeader(getAuthToken())
    return this.get(`stock-count-adjustment/${stockAdjId}`)
  }

  createStockAdjustment (payload) {
    this.setHeader(getAuthToken())
    return this.post('stock-count-adjustment', payload)
  }

  updateNotesRef (payload) {
    this.setHeader(getAuthToken())
    return this.patch('stock-count-adjustment/sku-note', payload)
  }

  createStockAdjustmentNote (payload) {
    this.setHeader(getAuthToken())
    return this.post('stock-count-adjustment/adjustment-note', payload)
  }

  getStockAdjustmentNote ({ stockAdjustmentNoteId, noteType }) {
    this.setHeader(getAuthToken())
    return this.get(`stock-count-adjustment/${stockAdjustmentNoteId}/${noteType}/adjustment-note`)
  }

  getStockAdjustmentSkus (query) {
    this.setHeader(getAuthToken())
    return this.get('stock-count-adjustment/skus', query)
  }

  exportStockAdjustmentSkusReport (query) {
    this.setHeader(getAuthToken())
    return this.get('stock-count-adjustment/export-report', query)
  }

  updateNoteInStockAdjustment (payload) {
    this.setHeader(getAuthToken())
    return this.patch('stock-count-adjustment/note-in-stock-adjustment', payload)
  }

  updateStockAdjustmentNoteStatus (payload) {
    this.setHeader(getAuthToken())
    return this.patch('stock-count-adjustment/adjustment-note-status', payload)
  }
}

export default StockCountAdjustmentProvider
