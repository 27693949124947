import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class InventoryProvider extends HttpRequest {
  getDeliveryNotesCount (query) {
    this.setHeader(getAuthToken())
    return this.get('/delivery-notes/count', query)
  }

  getDeliveryNoteCodes (query) {
    this.setHeader(getAuthToken())
    return this.get('/delivery-notes/ids/list', query)
  }

  getDeliveryNotes (query) {
    this.setHeader(getAuthToken())
    return this.get('/delivery-notes', query)
  }

  getDeliveryNoteById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/delivery-notes/${id}`)
  }

  getDeliveryNoteByCode (code) {
    this.setHeader(getAuthToken())
    return this.get(`/delivery-notes/${code}/code`)
  }

  getInTransitDelivery (query) {
    this.setHeader(getAuthToken())
    return this.get('/delivery-notes/in-transit-report', query)
  }

  getInTransitDeliveryCount (query) {
    this.setHeader(getAuthToken())
    return this.get('/delivery-notes/in-transit-report/count', query)
  }

  deleteDeliveryNotes (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/delivery-notes?id=${id}`)
  }

  createDeliveryNotes (payload) {
    this.setHeader(getAuthToken())
    return this.post('/delivery-notes', payload)
  }

  undoReceiveDeliveryNote (code, payload) {
    this.setHeader(getAuthToken())
    return this.post(`/delivery-notes/undo-dn/${code}/partial`, payload)
  }

  updateDeliveryNotes (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/delivery-notes/${id}`, payload)
  }

  updatePrintedDeliveryNote (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/delivery-notes/${id}/printed`)
  }

  updateDeliveryNoteReference (payload) {
    this.setHeader(getAuthToken())
    return this.patch('/delivery-notes/reference', payload)
  }

  updatePendingDeliveryNotes (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/delivery-notes/${id}/pending`, payload)
  }

  updateStatusDeliveryNotes (params, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/delivery-notes/${params.id}/${params.status}`, payload)
  }

  getWithdrawNotesCount (query) {
    this.setHeader(getAuthToken())
    return this.get('/withdraw-notes/count', query)
  }

  getWithdrawNotes (query) {
    this.setHeader(getAuthToken())
    return this.get('/withdraw-notes', query)
  }

  getWithdrawNoteById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/withdraw-notes/${id}`)
  }

  getInTransitWithdraw (query) {
    this.setHeader(getAuthToken())
    return this.get('/withdraw-notes/in-transit-report', query)
  }

  getInTransitWithdrawCount (query) {
    this.setHeader(getAuthToken())
    return this.get('/withdraw-notes/in-transit-report/count', query)
  }

  deleteWithdrawNotes (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/withdraw-notes?id=${id}`)
  }

  createWithdrawNotes (payload) {
    this.setHeader(getAuthToken())
    return this.post('/withdraw-notes', payload)
  }

  updateWithdrawNotes (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/withdraw-notes/${id}`, payload)
  }

  updatePrintedWithdrawNote (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/withdraw-notes/${id}/printed`)
  }

  updatePendingWithdrawNotes (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/withdraw-notes/${id}/pending`, payload)
  }

  updateStatusWithdrawNotes (params, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/withdraw-notes/${params.id}/${params.status}`, payload)
  }

  getStockMovement (query) {
    this.setHeader(getAuthToken())
    return this.get('/stock-movement', query)
  }

  getStockReserveHistory (query) {
    this.setHeader(getAuthToken())
    return this.get('/reserve-history', query)
  }

  getStockIn (query) {
    this.setHeader(getAuthToken())
    return this.get('/stock-movement-report/stock-in', query)
  }

  getStockSnapshot (query) {
    this.setHeader(getAuthToken())
    return this.get('/stock-movement-report/stock-snapshot', query)
  }

  getDeliveryNoteStatus (query) {
    this.setHeader(getAuthToken())
    return this.get('/delivery-notes/ids/status', query)
  }

  getWithdrawNoteStatus (query) {
    this.setHeader(getAuthToken())
    return this.get('/withdraw-notes/ids/status', query)
  }
}

export default InventoryProvider
